<template>
    <div>
        <div v-if="branch_orders_loading_status == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading orders...
        </div>
        <div v-else>
            <div class="grand-parent" v-if="showStats && selected_order_id === -1">
                <div class="parent">
                    <div>
                        <div class="card mini-stats-wid">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-3 newsize">
                                        <p class="text-muted fw-medium mb-2"></p>
                                        <h4 class="mb-3 text-center">Orders Overview</h4>
                                        <div id="chart">
                                            <apexchart type="donut" :options="chart.chartOptions" :series="chart.series" ref="myGraph"></apexchart>
                                        </div>
                                        <div class="float-right">
                                            <button class="btn btn-danger" v-if="graphFilterClicked.length > 0" @click="resetDonutGraphFilters">
                                                Reset Filters
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="parent">
                    <div class="children" v-if="statsOverview">
                        <div class="card mini-stats-wid">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">Active Orders</p>
                                        <h4 class="mb-0">
                                            {{ statsOverview.active_orders }}
                                        </h4>
                                    </div>
                                    <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                                        <span class="avatar-title"><i class="bx bx-copy-alt font-size-24"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- end card-body -->
                        </div>
                    </div>
                    <div class="children" v-if="statsOverview">
                        <div class="card mini-stats-wid">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">On time percentage</p>
                                        <h4 class="mb-0">
                                            {{ statsOverview.ontime_percentage }}
                                        </h4>
                                    </div>
                                    <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                                        <span class="avatar-title"><i class="bx bx-archive-in font-size-24"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- end card-body -->
                        </div>
                    </div>
                    <div class="children" v-if="statsOverview">
                        <div class="card mini-stats-wid">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">Orders for the month</p>
                                        <h4 class="mb-0">
                                            {{ statsOverview.orders_for_month }}
                                        </h4>
                                    </div>
                                    <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                                        <span class="avatar-title"><i class="bx bx-purchase-tag-alt font-size-24"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- end card-body -->
                        </div>
                    </div>
                    <div class="children" v-if="statsOverview">
                        <div class="card mini-stats-wid">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">Overdue orders</p>
                                        <h4 class="mb-0">
                                            {{ statsOverview.overdue_orders }}
                                        </h4>
                                    </div>
                                    <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                                        <span class="avatar-title"><i class="bx bx-time-five font-size-24"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- end card-body -->
                        </div>
                    </div>
                    <div class="children" v-if="statsOverview">
                        <div class="card mini-stats-wid">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">Total orders</p>
                                        <h4 class="mb-0">
                                            {{ statsOverview.total_orders }}
                                        </h4>
                                    </div>
                                    <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                                        <span class="avatar-title"><i class="bx bx bxs-box font-size-24"></i></span>
                                    </div>
                                </div>
                            </div>
                            <!-- end card-body -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- example -->
            <!-- <div class="card-body">
            <apexchart
                type="donut"
                :options="chart.chartOptions"
                :series="chart.series"
            ></apexchart>
        </div> -->

            <simpleLayout v-if="selected_order_id == -1" :items_count="branch_orders.length">
                <template #buttons>
                    <!-- <button type="button" class="btn btn-success mb-2 mr-2" @click="addOrder"><i class="mdi mdi-plus mr-1"></i> Add Order</button> --> 
                    <button type="button" class="btn btn-success mb-2 mr-2" @click="exportToExcel('ordersTable')">
                        <i class="mdi mdi-file-excel mr-1"></i> Export to Excel
                    </button>
                </template>
                <template #content>
                    <ordersTable />
                </template>
            </simpleLayout>

            <orderDetails v-if="selected_order_id != -1" />

            <!-- <ordersModal v-if="show_modal" /> -->
        </div>
    </div>
</template>

<script>
import { orderMethods, orderComputed, adminComputed } from "@/state/helpers";
import simpleLayout from "@/components/layout-templates/simple-layout.vue";
// import ordersModal from "./ordersModal.vue";
import ordersTable from "./ordersTable.vue";
import orderDetails from "./ordersDetails.vue";

export default {
    components: { simpleLayout, ordersTable, orderDetails },
    data: () => ({
        graphFilterClicked: "",
        statsOrders: {
            received: 0,
            confirmed: 0,
            processing: 0,
            waiting: 0,
            dispatched: 0,
        },
        show_modal: false,
        ready: false,
        //the charts config
        chart: {
            series: [],

            animations: {
                enabled: true,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
            },
            chartOptions: {
                // theme: {
                //     pallete: 9,
                // },
                chart: {
                    type: "donut",

                    events: {
                        //prettier-ignore
                        //eslint-disable-next-line
                        dataPointSelection: function (event, chartContext, config) {
                            //this will return "Received" "Dispatched" etc.
                            let donutSlice = config.w.config.labels[config.dataPointIndex];

                            let table = window.webix.$$("ordersTable");

                            //prettier-ignore
                            //NB: How these if statements work in this function
                            //if the donutslice name is set with a specific name e.g. Received
                            //if the filter is set that means that the slice is already selected, so reset the filter on the table and on the variable graphFilterClicked
                            //else set the filter and set the variable so that it is flagged that the donut slice is clicked
                            const graphFilter = (value) => {
                                //all other filters on the graph will use this if
                                // if (donutSlice == `${value}`) {
                                    //eslint-disable-next-line
                                    if (inst.graphFilterClicked == `${value}`) {
                                        table.filter("#status_name#", "");
                                        //eslint-disable-next-line
                                        inst.graphFilterClicked = "";
                                    } else {
                                        table.filter("#status_name#",`${value}`);
                                        //eslint-disable-next-line
                                        inst.graphFilterClicked = `${value}`;
                                    }
                                // }
                            };
                            //call the function and pass the argument from the text that the graph returns when clicking on a slice
                            graphFilter(donutSlice);
                        },
                        //eslint-disable-next-line
                        legendClick: (event, chartContext, config) => {
                            let table = window.webix.$$("ordersTable");

                            if (chartContext == 0) table.filter("#status_name#", "Received");

                            if (chartContext == 1) table.filter("#status_name#", "Confirmed");

                            if (chartContext == 2) table.filter("#status_name#", "Processing");
                            //prettier-ignore
                            if (chartContext == 3)
                                table.filter("#status_name#","Awaiting Collection");

                            if (chartContext == 4) table.filter("#status_name#", "Dispatched");
                        },
                    },
                },
                labels: ["Received", "Confirmed", "Processing", "Awaiting Courier Collection", "Dispatched"],
                // colors: ["#556ee6", "#34c38f", "#f46a6a"],
                legend: {
                    show: true,
                    position: "right",
                    horizontalAlign: "center",
                    verticalAlign: "middle",
                    floating: false,
                    fontSize: "30px",
                    offsetX: 0,
                },
                dataLabels: {
                    enabled: true,
                    //eslint-disable-next-line
                    formatter: (val, opt) => {
                        const actualVal = opt.w.globals.series[opt.seriesIndex];

                        return actualVal;
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 300,
                            },
                            legend: { show: false, position: "bottom" },
                        },
                    },
                ],
            },
        },
    }),
    watch: {
        //method watches if branch_orders in the store is switched when the branch is changed
        // not used at the moment becuase we are passing to getbranchorders -1 to get all the orders
        branch_orders() {
            this.determineOrdersStats();
        },
    },
    computed: {
        ...orderComputed,
        ...adminComputed,
        //will show the stats area up top if on the /orders url
        showStats() {
            if (this.$router.currentRoute.fullPath == "/orders") {
                return true;
            }
            return false;
        },
    },
    methods: {
        ...orderMethods,
        exportToExcel(id) {
            /* eslint-disable */
            return webix.toExcel($$(id), {
                ignore: { actions: true },
                filename: _.uniqueId("ordersExport_"), // for filename
                name: "Orders", // for sheet name
            });
            /* eslint-enable */
        },
        filterFromPie(e) {
            // let b = e;
            let table = window.webix.$$("ordersTable");

            table.filter("#status_name#", e);
        },
        addOrder() {
            // this.show_modal = true;
            // this.$nextTick(() => {
            //     this.$bvModal.show("ordersModal");
            //     this.$root.$once("bv::modal::hidden", (event) => {
            //         if (event.type == "hidden" && event.componentId == "ordersModal") {
            //             this.show_modal = false;
            //         }
            //     });
            // });
            this.$router.push("/create-order");
        },
        determineOrdersStats() {
            //this is necessary to reset the references in memory
            this.statsOrders = null;
            this.statsOrders = {
                received: 0,
                confirmed: 0,
                processing: 0,
                waiting: 0,
                dispatched: 0,
            };
            this.chart.series = null;
            this.chart.series = [];

            for (let i = 0; i < this.branch_orders.length; i++) {
                let status = this.branch_orders[i].status.id;

                if (status == "1") this.statsOrders.received++;
                if (status == "2") this.statsOrders.confirmed++;
                if (status == "3") this.statsOrders.processing++;
                if (status == "4") this.statsOrders.waiting++;
                if (status == "5") this.statsOrders.dispatched++;
            }

            this.chart.series.push(this.statsOrders.received);
            this.chart.series.push(this.statsOrders.confirmed);
            this.chart.series.push(this.statsOrders.processing);
            this.chart.series.push(this.statsOrders.waiting);
            this.chart.series.push(this.statsOrders.dispatched);
        },
        refreshPage() {
            // calls get branch
            // this.$router.go("/orders");
            this.ready = false;
            this.getBranchOrders(-1).then(() => {
                this.ready = true;
            });
        },
        resetDonutGraphFilters() {
            let table = window.webix.$$("ordersTable");
            table.filter("#status_name#", "");
            this.$refs.myGraph.resetSeries(true);

            this.graphFilterClicked = "";
        },
    },
    created() {
        //this method will refresh the table every 3 minutes, by calling the refreshPage method which calls the
        // getBranchOrders(-1) in the vue store, so the v-model for subsequent tables will auto refresh when the store is updated
        setInterval(this.refreshPage, 5 * 60 * 1000);
    },
    mounted() {
        //ensures if user nvaigated to another page that the id reset, else OrdersDetailPage will be rendered
        this.setSelectedOrder(-1);

        if (this.branch_orders.length == 0) {
            this.getBranchOrders(-1).then(() => {
                this.ready = true;
            });
        } else {
            this.determineOrdersStats();
            this.ready = true;
        }

        //needed for stats and graphs
        window.inst = this;

        this.resetDonutGraphFilters();
    },
    beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.grand-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.parent {
    margin: 5px;
    min-height: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
}

.newsize {
    min-width: 450px;
}

.children {
    margin: 5px;
    min-height: 20px;
    width: 332px;
}

@media only screen and (max-width: 1512px) {
    .grand-parent {
        flex-direction: column;
        // flex-direction: column-reverse;
    }
    // .hide-progress-bar {
    //     display: none;
    // }
    // .show-instead-of-progress-bar {
    //     display: unset;
    // }
}
</style>
